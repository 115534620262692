import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { OrganisationService } from '../../services/organisation/organisation.service';
import { Organisation } from 'src/app/shared/models/organisation.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsResolver  {
  constructor(
    private organisationService: OrganisationService,
    private appState: AppStateService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organisation[] | boolean> {
    // if there is only 1 organisation, a getOrganisation call may have happened after a refresh and we don't have a full organisation list
    const organisations = this.appState.get<Organisation[]>('organisations') || [];
    if (organisations.length <= 1 || route.queryParams.country ) {
      return this.organisationService.getOrganisations();
    } else {
      return of(organisations);
    }
  }
}
