@for (menuItem of navItems$ | async; track menuItem.title ) {
  <ul class="collapsable-menu">

    <li class="menu-group">
      <div class="menu-toggle flex flex-row items-center cursor-pointer" (click)="menuItem.expand = !menuItem.expand">
        <div class="flex">
          <mat-icon class="material-icons-outlined">{{ menuItem.icon }}</mat-icon>
        </div>
        <span class="flex basis-full">{{ menuItem.title }}</span>
        @if (menuItem.expand) {
          <span class="material-icons-outlined expander">
            expand_more
          </span>
        } @else {
          <span class="material-icons-outlined expander">
            expand_less
          </span>
        }
      </div>

      <ul class="menu-items" [ngClass]="{ expand: menuItem.expand, contract: !menuItem.expand }">
        @for (subMenuItem of menuItem.subMenu; track subMenuItem.path) {
          <li>
            <a [routerLink]="subMenuItem.path" class="flex flex-row items-center {{subMenuItem.class}}" (click)="clicked.emit(true)">
              <div class="flex">
                <mat-icon class="material-icons-outlined">
                  {{ subMenuItem.icon}}
                </mat-icon>
              </div>
              <span class="flex basis-full">{{ subMenuItem.title }}</span>
            </a>
          </li>
        }
      </ul>
    </li>
  </ul>
}
