import { Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';
import { AuthenticatedLayoutComponent } from './shared/components/authenticated-layout/authenticated-layout.component';
import { TokenLoginGuard } from './modules/auth/guards/loginToken/loginToken.guard';
import { ErrorComponent } from './modules/error/containers/error/error.component';
import { SingleLayoutComponent } from './shared/components/single-layout/single-layout.component';
import { contentResolver } from './shared/content/content.resolver';
import { NoAccessComponent } from './modules/error/containers/no-access/no-access.component';
import { AccessGuard } from './modules/auth/guards/access/access.guard';
import { APOLLO_USER_GROUPS } from './shared/models/global';
import { UsersResolver } from './modules/users/resolvers/users.resolver';
import { NotFoundComponent } from './modules/error/containers/not-found/not-found.component';
import { OrganisationsResolver } from './modules/organisations/resolvers/organisation/organisations.resolver';

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    runGuardsAndResolvers: 'always',
    canActivate: [TokenLoginGuard],
    loadChildren: () => import('./modules/auth/auth.routing').then(m => m.AUTH_ROUTES)
  },
  {
    path: 'error',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        component: ErrorComponent,
      },
    ]
  },
  {
    path: 'no-access',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        component: NoAccessComponent,
      },
    ]
  },
  {
    path: 'not-found',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent,
      },
    ]
  },
  /** Authenticated layout view **/
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      content: contentResolver,
      facilities: OrganisationsResolver
    },
    data: {
      loadContent: ['expiry-dialog:heading', 'expiry-dialog:body'],
      async: true,
    },
    children: [
      {

        path: 'organisations',
        canActivate: [AccessGuard],
        data: {
          groups: [APOLLO_USER_GROUPS.SUPER_ADMIN]
        },
        loadChildren: () => import('./modules/organisations/organisations.routing').then(m => m.ORGANISATION_ROUTES)
      },
      {
        path: 'facilities',
        canActivate: [AccessGuard],
        data: {
          groups: [APOLLO_USER_GROUPS.SUPER_ADMIN]
        },
        loadChildren: () => import('./modules/facilities/facilities.routing').then(m => m.FACILITY_ROUTES)
      },
      {
        path: 'users',
        canActivate: [AccessGuard],
        data: {
          groups: [APOLLO_USER_GROUPS.SUPER_ADMIN]
        },
        resolve: {
          users: UsersResolver
        },
        loadChildren: () => import('./modules/users/users.routing').then(m => m.USER_ROUTES)
      },
      {
        path: 'media',
        loadChildren: () => import('./modules/media/media.routing').then(m => m.MEDIA_ROUTES)

      },
      {
        path: 'events',
        loadChildren: () => import('./modules/events/events.routing').then(m => m.EVENTS_ROUTES)

      },
      { path: '**', redirectTo: 'organisations' }
    ]
  },

];

