import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CollapsableMenuComponent } from '../collapsable-menu/collapsable-menu.component';
import { LogoComponent } from '../logo/logo.component';
import { navItems } from './side-nav.config';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [LogoComponent, MatIconModule, MatButtonModule, CollapsableMenuComponent],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Output() toggleSideNav = new EventEmitter();
  @Input() isMobile: boolean;
  navItems = navItems;

  constructor() { }

  ngOnInit(): void {
  }
  toggleSidneNav() {
    this.toggleSideNav.emit('toggle');
  }

}
