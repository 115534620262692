import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from '../../../../shared/services/api/api.service';
import { AppStateService } from '../../../../shared/services/app-state/app-state.service';
import { Organisation } from 'src/app/shared/models/organisation.model';

export interface UpsertOrganisationInput {
  id?: string;
  name: string;
  musicPooling: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }



  upsertOrganisation(input: UpsertOrganisationInput) {
    const statement = `
      mutation upsertOrganisation($input: UpsertOrganisationInput!) {
        upsertOrganisation(input: $input) {
          id
          name
          musicPooling
          musicPoolingSettings {
            size
          }
        }
      }
    `;
    return this.apiService
      .graphql<Organisation>({ statement, variables: { input }, type: 'upsertOrganisation' })
      .pipe(
        tap(organisation => {
          const organisations = this.appState.get<Organisation[]>('organisations')
            .map(val => {
              if ( val.id === input.id) {
                return {
                  ...val,
                  ...organisation,
                }
              }
              return val
            })
          this.appState.set<Organisation[]>('organisations', organisations);
        })
      )
  }


  getOrganisations(): Observable<Organisation[]> {

    const statement = `
      query getOrganisations {
        getOrganisations {
          id
          name
          logo
          root
          musicPooling
          musicPoolingSettings {
            size
          },
          facilities {
            name
            displayName
            id
          }
        }
      }
    `;
    return this.apiService
      .graphql<Organisation[]>({ statement, variables: {}, type: 'getOrganisations' })
      .pipe(
        tap(organisations => {
          this.appState.set<Organisation[]>('organisations', organisations);
        })
      )
  }

  getOrganisation(id: string): Observable<Organisation> {
    const statement = `
      query getOrganisation($id: ID!) {
        getOrganisation(id: $id) {
          id
          name
          musicPooling
          root
          musicPoolingSettings {
            size
          }
        }
      }
    `;
    return this.apiService
      .graphql<Organisation>({ statement, variables: { id }, type: 'getOrganisation' })
      .pipe(
        tap(organisation => {
          const orgs = this.appState.get<Organisation[]>('organisations') || [];
          const newOrgs = [...orgs.filter(org => org.id !== organisation.id), organisation].sort((a,b) => a.name > b.name ? -1 : 1);
          this.appState.set<Organisation[]>('organisations', newOrgs);
        })
      )
  }
}
