import { NavItem } from '../collapsable-menu/collapsable-menu.component';

export const navItems: NavItem[] = [
  {
    path: '', title: 'Organisations', icon: 'business', class: 'sub', isExternalLink: false, expand: true, subMenu: [
      { path: '/organisations/list', title: 'List', icon: 'list', class: '', isExternalLink: false, subMenu: [] },
    ]
  },
  {
    path: '', title: 'Facilities', icon: 'storefront', class: 'sub', isExternalLink: false, expand: false, subMenu: [
      { path: '/facilities/list', title: 'List', icon: 'list', class: '', isExternalLink: false, subMenu: [] },
    ]
  },
  {
    path: '', title: 'Media', icon: 'auto_awesome', class: 'sub', isExternalLink: false, expand: false, subMenu: [
      { path: '/media/music', title: 'Music', icon: 'library_music', class: '', isExternalLink: false, subMenu: [] },
      { path: '/media/video', title: 'Video', icon: 'videocam', class: '', isExternalLink: false, subMenu: [] },
      { path: '/media/tags', title: 'Tags', icon: 'sell', class: '', isExternalLink: false, subMenu: [] },
    ]
  },
  {
    path: '', title: 'Homepage', icon: 'space_dashboard', class: 'sub', isExternalLink: false, expand: false, subMenu: [
      { path: '/events', title: 'Events', icon: 'event', class: '', isExternalLink: false, subMenu: [] },
      // { path: '/media/video', title: 'Panels', icon: 'east', class: '', isExternalLink: false, subMenu: [] },
    ]
  }

]