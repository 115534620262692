import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { AppStateService } from '../../../../shared/services/app-state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard {
  constructor(
    private authService: AuthService,
    private appState: AppStateService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn()
      .pipe(
        map(() => {
          if (!this.authService.hasGroupAccess(route.data.groups)) {
            return this.router.parseUrl('/not-found');
          }
          return true;
        })
      )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isLoggedIn()
      .pipe(
        map(() => {
          if (!this.authService.hasGroupAccess(route.data.groups)) {
            return this.router.parseUrl('/not-found');
          }
          return true;
        })
      )
  }
}

